import React from 'react'
import tw, { styled } from 'twin.macro'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import SEO from '../components/seo'

const Wrapper = styled.div`
  ${tw`w-full lg:w-3/4 xl:w-2/3 py-10 lg:py-20 mx-auto`};
`
const Title = styled.h2`
  ${tw`text-black dark:text-white font-normal text-3xl md:text-5xl`};
`
const Body = styled.div`
  ${tw`text-lg`};
`
class AboutPage extends React.Component {
  render() {
    const [about] = get(this, 'props.data.allContentfulAbout.edges')
    return (
      <>
        <SEO
          title="About | James Does Digital"
          keywords={[
            `developer`,
            `blog`,
            `articles`,
            `aws`,
            `cloud`,
            `react native`,
            `react`,
            `graphql`,
          ]}
        />
        <Wrapper>
          <Title>{about.node.headline}</Title>
          <Body
            dangerouslySetInnerHTML={{
              __html: about.node.aboutBodyText.childMarkdownRemark.html,
            }}
          />
        </Wrapper>
      </>
    )
  }
}

export default AboutPage

export const pageQuery = graphql`
  query AboutQuery {
    allContentfulAbout {
      edges {
        node {
          headline
          aboutBodyText {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }
  }
`
